<template>
  <div class="top_wrap">
    <img class="logo_img" src="../assets/images/logo.png" />
    <div class="search_box">
      <el-input
        v-model="searchVal"
        prefix-icon="el-icon-search"
        placeholder="请输入关键字搜索"
      >
        <el-button slot="append" type="primary" @click="handleSearch"
          >搜索</el-button
        >
      </el-input>
    </div>
    <div class="menu_wrap">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item index="1">首页</el-menu-item>
        <el-menu-item index="2">机构介绍</el-menu-item>
        <el-menu-item index="3">资讯中心</el-menu-item>
        <el-menu-item index="5">信息公开</el-menu-item>
        <el-submenu index="4">
          <template slot="title">业务办理</template>
          <el-menu-item
            index="4-1"
            v-for="(item, index) in jobs"
            :key="index"
            @click="goUrl(item.url)"
            >{{ item.title }}</el-menu-item
          >
          <!-- <el-menu-item index="4-2">电子书屋</el-menu-item>
          <el-menu-item index="4-3">汇聚良缘</el-menu-item>
          <el-menu-item index="4-4">网上办事</el-menu-item> -->
        </el-submenu>
        <el-menu-item index="6">专题专栏</el-menu-item>
        <el-menu-item index="7">劳动风采</el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Top',
  props: {
    msg: String
  },
  data() {
    return {
      activeIndex: '1',
      searchVal: ''
    }
  },
  computed: {
    jobs() {
      return this.$store.state.jobs
    }
  },
  watch: {
    $route(to, from) {
      let path = to.path
      if (path === '/news') this.activeIndex = '3'
      if (path === '/synopsis') this.activeIndex = '2'
      if (path === '/info') this.activeIndex = '5'
      if (path === '/craftsman') this.activeIndex = '7-1'
      if (path === '/work') this.activeIndex = '7-2'
    }
  },
  methods: {
    handleSearch() {
      this.$router.push({
        path: '/article',
        query: { search: this.searchVal }
      })
    },
    goUrl(url) {
      window.open(url, '_blank')
    },
    handleSelect(val) {
      console.log(val)
      if (val === '1') this.$router.push('/')
      if (val === '3') this.$router.push('/news')
      if (val === '2') this.$router.push('/synopsis')
      if (val === '5') this.$router.push('/info')
      if (val === '7') this.$router.push('/work')
      if (val === '6') this.$router.push('/craftsman')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/css/variables.scss';
// .top_wrap {
//   width: min-content;
// }
:deep .el-input-group__append {
  // background-color: $color-primary;
  // color: #fff;
  // border: 2px solid $color-primary;
}
.el-input-group__append {
  border: none;
}
.logo_img {
  // width: 340px;
  // position: absolute;
  // top: 0;
  // left: 0;
  // z-index: -99;
  width: 100%;
}
.search_box {
  top: 100px;
  right: 100px;
  position: absolute;
  width: 400px;
  border: 2px solid $color-primary;
  border-radius: 4px;
}
.menu_wrap {
  // width: $content-width;
  // margin: 0 auto 20px;
  // display: flex;
  // justify-content: space-between;
  margin: 0 auto;
  background-color: $color-primary;
  :deep .el-menu-item,
  :deep .el-submenu__title {
    padding: 0 40px;
    font-size: 16px;
    color: #fff;
  }
}
:deep .el-menu--horizontal > .el-submenu .el-submenu__title {
  color: #fff;
}
.el-menu-demo {
  width: $content-width;
  margin: 0 auto;
  background: transparent;
  color: #fff;
}
:deep .el-menu--horizontal {
  .el-menu-item.is-active,
  .el-submenu.is-active .el-submenu__title {
    color: #333;
    background-color: #fff;
  }
}

@media screen and (max-width: 768px) {
  .top_wrap {
    background-color: $color-primary;
  }
  .search_box {
    position: relative;
    top: 0;
    left: 0;
    width: 90%;
    margin: 10px auto;
    border: 2px solid $color-primary;
    border-radius: 4px;
  }
  .el-menu-demo {
    width: max-content;
    // overflow: scroll;
    height: 45px;
  }
  .menu_wrap {
    // margin: 100px auto 0;
    // width: $content-width;
    margin: 0 auto;
    overflow-y: scroll;
    :deep .el-menu-item,
    :deep .el-submenu__title {
      padding: 0 20px;
      font-size: 16px;
    }
  }
  :deep .el-menu--horizontal {
    .el-submenu .el-submenu__title,
    .el-menu-item {
      height: 45px;
      line-height: 45px;
    }
  }
}
</style>
